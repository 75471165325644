.treeSubMenuIconWrap
    cursor: pointer !important
    min-width: 0 !important
    padding: 0 !important
    text-align: center !important

    &[aria-expanded=true]
        .fa-angle-down
            display: none

        .fa-angle-up
            display: block

    &[aria-expanded=false]
        .fa-angle-down
            display: block

        .fa-angle-up
            display: none

.note-editable
    background-color: white

.opacity-0
    opacity: 0

.opacity-25
    opacity: .25

.opacity-50
    opacity: .5

.opacity-75
    opacity: .75

.opacity-100
    opacity: 1

label[data-toggle=tooltip]
    &:after
        font-family: "Font Awesome 6 Pro"
        display: inline-block
        content: "\f059"
        color: rgba(0, 0, 0, 0.7)
        //font-weight: 900
        font-size: 0.6rem
        position: relative
        top: -0.5rem
        left: 0.3rem

.content-tree-item
    width: 100%

    .edit-hover
        display: none
        opacity: 0.75
        color: red
        padding-left: 20px

    &:hover
        & > div > div > .edit-hover,
        & > div > .edit-hover
            display: inline-block

.page-leftheader .breadcrumb-item + .breadcrumb-item::before
    padding-left: 0.5rem !important


.sidenav-toggled .app-sidebar__logo a
    flex-direction: column


.app-sidebar
    overflow-y: scroll

.side-menu__icon i
    font-size: 1rem
    transform: translateY(-7px)

.note-editing-area ul
    list-style-type: disc !important
    list-style-position: inside


#media-modal
    .modal-header
        .modal-close
            color: #ef4b4b
            cursor: pointer

    .modal-body
        .form-label
            font-weight: 500 !important

table.dataTable
    tbody
        tr.selected, td.selected, th.selected
            background-color: #705ec8

            a
                color: white

.dataTables_processing
    z-index: 1

.select2
    .selection
        .select2-selection__rendered
            .select2-selection__choice
                padding-right: 20px
                position: relative

                &::after
                    display: inline-block
                    font-family: "Font Awesome 6 Pro"
                    font-weight: 900
                    content: "\f0b2"
                    cursor: pointer
                    z-index: 5
                    position: absolute
                    right: 2.5px

                    &:hover
                        cursor: grab
                        color: rgba(0, 0, 0, 1)

                    &:active
                        cursor: grabbing
                        color: rgba(0, 0, 0, 1)

                &:first-child
                    background-color: #adadad !important

    &.is-invalid .select2-selection, &.needs-validation ~ span > .select2-dropdown
        border-color: red !important


.DTE_Inline
    .DTE_Inline_Field
        display: inline-block

        .form-group
            margin-bottom: 0

        .DTE_Field
            label
                display: none

    .DTE_Inline_Buttons
        display: inline-block


.dataTable
    thead
        .underline
            text-decoration: none

    .underline
        text-decoration: underline dashed rgba(0, 0, 0, 0.50)
        text-underline-offset: 0.2em

.icon-draggable
    cursor: move
    /* fallback if grab cursor is unsupported */
    cursor: grab

    &:active
        cursor: grabbing
        cursor: -moz-grabbing
        cursor: -webkit-grabbing

.swal2-container
    z-index: 10001 !important

.card-title
    text-transform: initial !important

.input-group-text
    line-height: 1.5 !important
    border-radius: 4px !important

.select2-container--open
    z-index: 10061

.custom-switch-indicator
    cursor: pointer

.dark-mode
    .note-editor
        button
            color: rgb(133, 139, 167)

    label[data-toggle=tooltip]
        &:after
            color: rgb(133, 139, 167)

    .reorder-handle
        color: rgb(133, 139, 167) !important


.widgets
    .card
        height: 100%

        .card-body
            height: 100%
