.focal-dot-style
    display: inline-block
    border-radius: 10px
    width: 6px
    height: 6px
    background-color: #00f8ff
    box-shadow: 0 0 8px #1f3275

#media-modal
    .image-wrapper
        position: relative

        .focal-dot
            transition: all 200ms ease-in-out
            position: absolute
            display: block
            left: 50%
            top: 50%
            transform: translateX(-50%) translateY(-50%)
            z-index: 1

.dark-mode
    .cms-image-picker--wrapper
        border: 1px solid rgb(133, 139, 167)

.cms-image-picker--wrapper
    margin-right: 1rem
    margin-top: 2rem
    transition: all 200ms ease-in-out
    position: relative
    border-radius: 8px
    border: 1px solid #dbe2eb

    &:before
        content: ""
        display: block
        padding-top: 100%

    &:hover
        box-shadow: 0 6px 8px rgb(4 4 7 / 10%)

.cms-image-picker--wrapper
    border-radius: 8px


    .plus-placeholder
        transition: all 200ms ease-in-out
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        cursor: pointer
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        border-radius: 8px

        &:before
            transition: all 200ms ease-in-out
            font-family: "Font Awesome 6 Pro"
            //color: rgba(0, 0, 0, 0.8)
            color: rgba(0, 0, 0, 0)
            content: "\f03e"
            display: block
            font-size: 2rem
            position: absolute
            top: 50%
            left: 50%
            transform: translateX(-50%) translateY(-50%)
            z-index: 3

        &:after
            transition: all 200ms ease-in-out
            content: " "
            display: block
            //background-color: rgba(0, 0, 0, 0.4)
            background-color: rgba(0, 0, 0, 0)
            z-index: 2
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            border-radius: 8px

        &:hover
            &:after
                background-color: rgba(0, 0, 0, 0.4)

            &:before
                color: rgba(0, 0, 0, 0.8)
                text-shadow: 0 0 5px #FFFFFF

        &.empty
            background-image: none !important

            &:after
                display: none

            &:before
                font-family: "Font Awesome 6 Pro"
                color: rgb(0, 0, 0, 0.4)
                content: "\f055"
                font-weight: 900
                background-color: rgba(0, 0, 0, 0) !important

            &:hover:before
                color: rgb(0, 0, 0, 0.6)

    .drag
        position: absolute
        top: 0.5rem
        right: -1.2rem
        color: rgba(0, 0, 0, 0.6)
        display: block
        cursor: pointer
        z-index: 5

        &:hover
            cursor: grab
            color: rgba(0, 0, 0, 1)

        &:active
            cursor: grabbing
            color: rgba(0, 0, 0, 1)

    .trash
        position: absolute
        top: -1rem
        right: -1.2rem
        color: rgba(255, 0, 0, 0.6)
        display: block
        cursor: pointer
        z-index: 5

        &:hover
            color: rgba(255, 0, 0, 1)

        &.empty
            display: none

