.modal-dialog-slideout
    min-height: 100%
    margin: 0 0 0 auto
    background: #fff

.modal:not(.show) .modal-dialog.modal-dialog-slideout

    transform: translateX(110vw) scale(1)

.modal.show .modal-dialog.modal-dialog-slideout
    display: flex
    align-items: stretch
    height: 100%
    border-bottom-left-radius: 5px
    border-top-left-radius: 5px
    border-bottom-right-radius: 0 !important
    border-top-right-radius: 0 !important
    width: var(--width)
    max-width: 90vw !important
    //transition: width 400ms ease-in-out

@media (max-width: 768px)
    .modal.show .modal-dialog.modal-dialog-slideout
        width: 100vw !important
        max-width: 100vw !important
        border-radius: 0 0 0 0 !important

.modal.show .modal-dialog.modal-dialog-slideout .modal-body
    overflow-y: auto
    overflow-x: hidden

.modal-dialog-slideout .modal-content
    border: 0
    overflow-y: auto
    overflow-x: hidden

.modal-dialog-slideout .modal-header, .modal-dialog-slideout .modal-footer
    height: 69px
    display: block

.modal-dialog-slideout .modal-header h5
    float: left


#v2_modal
    position: fixed
    left: 0
    right: 0
    bottom: 0
    top: 0

    #v2_modal_form
        .select2
            width: 100% !important

    .modal-content
        overflow-y: auto

#modalResizer
    position: absolute
    left: 0
    top: 0
    bottom: 0
    z-index: 99999
    width: 10px
    background-color: #3b446a
    display: flex
    align-items: center
    justify-content: center

    &:hover
        background-color: #1f2438 !important
        cursor: col-resize

